import { Button, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { convertBlobToBase64 } from "./utils";
import { fetchSTTWhisper } from "../../APIS/clientAPIS";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  // Tooltip,
  // MenuItem,
  // Select,
} from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Mic, Pause, PlayArrow, Stop } from "@mui/icons-material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Constants

const CHUNK_INTERVAL = 5 * 60 * 1000; // 5 min

const NewAudioVisualizer = ({ transcription, state }) => {
  // Refs
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const audioChunksRef = useRef([]);
  const sourceRef = useRef(null);
  const animationIdRef = useRef(null);
  const timerIntervalRef = useRef(null);

  // State
  const [isRecording, setIsRecording] = useState(false);
  const [recordingLive, setRecordingLive] = useState(false);
  const [waitingTranscriptionData, setWaitingTranscriptionData] =
    useState(false);
  // const [language, setLanguage] = useState("en");
  const [timer, setTimer] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  // Detect Safari
  const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // Get supported MIME type
  const getSupportedMimeType = () =>
    isSafari() ? "audio/mp4" : "audio/webm;codecs=opus";

  // Initialize Audio
  const initAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;

      sourceRef.current =
        audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);
    } catch (error) {
      console.error("Error initializing audio:", error);
      enqueueSnackbar("Microphone access denied.", { variant: "error" });
    }
  };

  // Start Recording
  const startRecording = async () => {
    try {
      if (!streamRef.current) {
        await initAudio();
      }

      audioChunksRef.current = [];
      const mimeType = getSupportedMimeType();

      mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
        mimeType,
      });

      mediaRecorderRef.current.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = sendChunkedAudio;

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingLive(true);
      setTimer(0);
      startTimer();
      visualize();
    } catch (error) {
      console.error("Error starting recording:", error);
      enqueueSnackbar("Error starting recording.", { variant: "error" });
    }
  };

  // Stop Recording and Restart
  const stopRecording = async () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingLive(false);
      setWaitingTranscriptionData(true);
      cancelAnimationFrame(animationIdRef.current);
      clearCanvas();
    }
  };

  // Function to clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const canvasCtx = canvas.getContext("2d");
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };
  const tempStopRecording = async () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      cancelAnimationFrame(animationIdRef.current);
    }
  };

  // Send Chunked Audio
  const sendChunkedAudio = async () => {
    if (!mediaRecorderRef.current || audioChunksRef.current.length === 0)
      return;

    try {
      const mimeType = getSupportedMimeType();
      const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
      const base64String = await convertBlobToBase64(audioBlob);

      const formattedBase64 = base64String.split(";base64,")[1];

      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({ base64_audio: formattedBase64 })
        );
        transcription(whisperTranscription || "");
        setWaitingTranscriptionData(false);
        // enqueueSnackbar("Chunk uploaded successfully.", { variant: "success" });
      }
    } catch (error) {
      console.error("Error sending chunked audio:", error);
      enqueueSnackbar("Error processing audio.", { variant: "error" });
    }

    audioChunksRef.current = []; // Clear for next chunk
  };

  // Timer Logic
  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const visualize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    const analyser = analyserRef.current;
    if (!analyser) return;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationIdRef.current = requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      // Clear the canvas
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      // Set bar width and spacing
      const barWidth = 2; // Thin bars
      const totalBarWidth = barWidth * bufferLength; // Total width of all bars
      const offsetX = (canvas.width - totalBarWidth) / 2; // Center the bars horizontally

      const centerY = canvas.height / 2; // The center line for the bars

      let x = offsetX; // Start drawing from the offset to center the bars

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2; // Scale the bar height

        // Set the color of the bars
        canvasCtx.fillStyle = `#D92D20`;

        // Draw the bar above and below the center line
        canvasCtx.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);

        // Move to the next bar position
        x += barWidth + 1; // Add 1px space between bars
      }
    };

    draw();
  };

  // Automatically stop and restart recording every 30 seconds
  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        tempStopRecording();
        setTimeout(() => startRecording(), 300); // Short delay before restarting
      }, CHUNK_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [isRecording]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      cancelAnimationFrame(animationIdRef.current);
      clearInterval(timerIntervalRef.current);
    };
  }, []);

  return (
    <div>
      {state === "editor" && (
        <div className="d-flex flex-column  align-items-center">
          <div
            style={{
              background: "white",
              width: "100%",
              boxShadow: "0px 20px 50px 0px var(--primary-accent-color)",
            }}
            className=" row row-cols-3 mb-4 w-100 rounded-bottom border-top py-2"
          >
            <div className="d-flex justify-content-center align-items-center">
              <canvas
                ref={canvasRef}
                style={{
                  height: "30px",
                  width: "100%",
                }}
              ></canvas>
            </div>

            <div className="d-flex justify-content-center gap-2 align-items-center">
              {!recordingLive && (
                <div className="d-flex align-items-center gap-2">
                  {/* <Tooltip
                    title="Select the language you will be speaking in. This will help us generate a more accurate transcription."
                    placement="top"
                    arrow
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                  <Select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    IconComponent={({ className }) => (
                      <KeyboardArrowDownIcon
                        className={className}
                        sx={{ color: "black" }}
                      />
                    )}
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      height: "32px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        padding: "4px 32px 4px 8px",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: "auto",
                        },
                        sx: {
                          "& .MuiMenuItem-root": {
                            padding: "4px 8px",
                            minHeight: "32px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                    
                  </Select> */}
                  <IconButton
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      textTransform: "capitalize",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "var(--primary-color-darken)",
                      },
                    }}
                    id="notetaker_record_button"
                    onClick={startRecording}
                  >
                    <Mic />
                  </IconButton>
                </div>
              )}

              {recordingLive && (
                <Button
                  id="notetaker_stop_record_button"
                  className=" rounded-3 px-2"
                  sx={{
                    backgroundColor: "red",
                    textTransform: "capitalize",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                  onClick={stopRecording}
                >
                  <Stop /> Stop
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {/* {isRecording && <>{formatTime(timer)}/60:00</>}{" "} */}
              {/* {audioBlob && (
            <Button
              sx={{
                backgroundColor: "white",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              className=" rounded-3"
              startIcon={<HighlightOff />}
              onClick={clearRecording}
            >
              Cancel
            </Button>
          )} */}
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={waitingTranscriptionData}
      >
        <CircularProgress size={24} color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewAudioVisualizer;
