export const relationshipStatuses = [
  "Single",
  "Married",
  "Divorced",
  "Separated",
  "Widowed",
  "In a Domestic Partnership",
  "In a Civil Union",
  "Co-habiting",
  "Engaged",
  "Complicated",
];
export const maritalFunctioningStatuses = [
  "Satisfactory",
  "Highly Satisfactory",
  "Stable but Routine",
  "Occasionally Stressful",
  "Frequently Stressful",
  "In Conflict",
  "Separated/Considering Separation",
  "In Counseling/Therapy",
  "Recovering/Rebuilding",
  "Co-parenting",
  "Open/Non-Monogamous",
  "Enduring/Long-Term Challenges",
];
export const employmentStatuses = [
  "Employed Full-Time",
  "Employed Part-Time",
  "Self-Employed",
  "Unemployed",
  "Not in the Labor Force",
  "Retired",
  "Student",
  "Homemaker",
  "On Disability",
  "Temporary Leave",
  "Underemployed",
  "Seasonal",
];
export const socialFunctioningStatuses = [
  "None",
  "Independent",
  "Socially Active",
  "Moderately Social",
  "Socially Isolated",
  "Difficulty in Social Situations",
  "Limited by Physical Health",
  "Limited by Mental Health",
  "Supportive Family/Friends",
  "Lacking Support",
  "Dependent on Caregivers",
  "Engaged in Community Services",
  "Experiencing Conflict in Relationships",
  "Social Skills Development",
  "Rebuilding Social Networks",
];
export const academicFunctioning = [
  "None",
  "Moderate-Severe impairment",
  "Mild impairment",
  "Moderate impairment",
  "Severe impairment",
];
export const expertTypeList = [
  "Psychologist",
  "Psychiatrist",
  "Therapist",
  "Clinical Social Worker",
  "Mental Health and Wellness Coach",
  "Counselor",
  "Psychotherapist",
  "Cardiologist",
  "Endocrinologist",
  "Neurologist",
  "Gastroenterologist",
  "Physical Therapist",
  "Occupational Therapist",
  "Dietitian/Nutritionist",
  "Personal Trainer/Coach",
  "Life Coach",
  "Speech-Language Pathologist",
  "Addiction Specialist",
  "Palliative Care Specialist",
  "Genetic Counselor",
  "Rheumatologist",
  "Pediatrician",
  "Ophthalmologist",
  "Nephrologist",
  "Urologist",
  "Oncologist",
  "Dermatologist",
  "Orthopedic Surgeon",
  "ENT (Ear, Nose, and Throat) Specialist",
  "Pulmonologist",
  "Hematologist",
  "Immunologist",
  "Infectious Disease Specialist",
  "Obstetrician/Gynecologist",
  "Geriatrician",
  "Pain Management Specialist",
  "Rehabilitation Specialist",
  "Sports Medicine Specialist",
  "Allergist",
  "Nurse Practitioner",
  "Registered Nurse",
  "Hospice Care Provider",
  "Acupuncturist",
  "Chiropractor",
  "Emergency Medicine Specialist",
  "Anesthesiologist",
  "Radiologist",
  "Podiatrist",
  "Vascular Surgeon",
  "Plastic Surgeon",
];

export const frequencyList = [
  "Every Day (QD)",
  "Every Two Days (QAD)",
  "Twice Daily (BID)",
  "Thrice Daily (TID)",
  "Four Times Daily (QDS)",
  "Every Day Before Noon (QAM)",
  "Every Night at Bedtime (QHS)",
  "Every Day after Noon (QPM)",
  "Every Hour (QH)",
  "Every 2 Hours (Q2H)",
  "Every 3 Hours (Q3H)",
  "Every 4 Hours (Q4H)",
  "Every 6 Hours (Q6H)",
  "Every 8 Hours (Q8H)",
  "Every 12 Hours (Q12H)",
  "Every Week (QWK)",
  "Every Month",
  "Before Meals (AC)",
  "After Meals (PC)",
  "As Needed (PRN)",
  "Emergency (SOS)"
];

export const dosageForm = [
  "Aerosol",
  "Bar",
  "Bead",
  "Capsule",
  "Cellular Sheet",
  "Chewable Gel",
  "Cloth",
  "Concentrate",
  "Cream",
  "Crystal",
  "Disc",
  "Douche",
  "Dressing",
  "Drug-eluting Contact Lens",
  "Elixir",
  "Emulsion",
  "Enema",
  "Extract",
  "Fiber",
  "Film",
  "Flower",
  "For Solution",
  "For Suspension",
  "Gas",
  "Gel",
  "Globule",
  "Granule",
  "Gum",
  "Herb",
  "Implant",
  "Inhalant",
  "Injectable Foam",
  "Injectable, Liposomal",
  "Injection",
  "Insert",
  "Intrauterine Device",
  "Irrigant",
  "Jelly",
  "Kit",
  "Liniment",
  "Lipstick",
  "Liquid",
  "Lotion",
  "Lozenge",
  "Mouthwash",
  "Oil",
  "Ointment",
  "Paste",
  "Pastille",
  "Patch",
  "Pellet",
  "Pill",
  "Plaster",
  "Poultice",
  "Powder",
  "Ring",
  "Rinse",
  "Salve",
  "Shampoo",
  "Soap",
  "Solution",
  "Sponge",
  "Spray",
  "Stick",
  "Strip",
  "Suppository",
  "Suspension",
  "Swab",
  "Syrup",
  "System",
  "Tablet",
  "Tampon",
  "Tape",
  "Tincture",
  "Troche",
  "Wafer",
  "Magma",
  "Cachet"
]





export const routeList = [
  "Orally",
  "Sublingually",
  "Buccally",
  "Rectally",
  "Intravenously (IV)",
  "Intramuscularly (IM)",
  "Subcutaneously",
  "Intraarterially (IA)",
  "Transnasally",
  "via Inhalation",
  "Vaginally",
  "Transdermally",
  "Intraosseously (IO)"
];

export const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Others",
    label: "Others",
  },
];


export const takenWithList = [
  "With Food",
  "With Plenty of Water",
  "With Milk",
  "On an Empty Stomach",
  "With Meals",
  "Without Alcohol",
  "With Fruit Juice",
  "Without Grapefruit Juice",
  "With a Full Glass of Water",
  "With or Without Food",
];

export const SessionNote = [
  {
    id: "chief_complaints",
    field: "Session Note",
  },
  {
    id: "history_of_present_illness_hpi",
    field: "Session Note",
  },
  {
    id: "session_summary",
    field: "Session Note",
  },
  {
    id: "homework_assigned",
    field: "Session Note",
  },
  {
    id: "future_treatment_plan",
    field: "Session Note",
  },
  {
    id: "medicines",
    field: "Session Note",
  },
  {
    id: "lab_test",
    field: "Session Note",
  },
  {
    id: "follow_up_date",
    field: "Session Note",
  },
];


export const Followupsummary = [
  {
    id: "compliance",
    field: "Follow-Up Summary",
  },
  {
    id: "treatment_plan_progress",
    field: "Follow-Up Summary",
  },
  {
    id: "assessment_summary",
    field: "Follow-Up Summary",
  },
  {
    id: "worksheet_journal_summary",
    field: "Follow-Up Summary",
  },
  {
    id: "stella_sos_summary",
    field: "Follow-Up Summary",
  }
];

export const CaseHistory = [
  {
    id: "medical_history",
    field: "Case History",
  },
  {
    id: "history_of_self_harm_aggression",
    field: "Case History",
  },
  {
    id: "history_of_trauma",
    field: "Case History",
  },
  {
    id: "history_of_contagious_medical_conditions",
    field: "Case History",
  },
  {
    id: "medication_history",
    field: "Case History",
  },
  {
    id: "hospitalization_history",
    field: "Case History",
  },
  {
    id: "surgical_history_shx",
    field: "Case History",
  },
  {
    id: "histopathological_pathological_history",
    field: "Case History",
  },
  {
    id: "allergy_history",
    field: "Case History",
  },
  {
    id: "nutritional_history",
    field: "Case History",
  },
  {
    id: "substance_use_issues",
    field: "Case History",
  },
  {
    id: "genetics",
    field: "Case History",
  },
  {
    id: "family_history_of_mental_illness_or_substance_abuse_issues",
    field: "Case History",
  },
  {
    id: "psychosocial_history",
    field: "Case History",
  },
  {
    id: "languages_spoken",
    field: "Case History",
  },
  {
    id: "lifestyle_history",
    field: "Case History",
  },
  {
    id: "developmental_history",
    field: "Case History",
  },
  {
    id: "marital_status",
    field: "Case History",
  },
  {
    id: "employment_status",
    field: "Case History",
  },
  {
    id: "marital_functioning",
    field: "Case History",
  },
  {
    id: "social_functioning",
    field: "Case History",
  },
  {
    id: "academic_functioning",
    field: "Case History",
  },
];

export const Prescription = [
  {
    id: "vitals",
    field: "Prescription",
  },
  {
    id: "mental_status_exam_results",
    field: "Prescription",
  },
  {
    id: "physical_exam_results",
    field: "Prescription",
  },
  {
    id: "histopathological_pathological_diagnostics",
    field: "Prescription",
  },
  {
    id: "imaging_and_radiological_diagnostics",
    field: "Prescription",
  },
  {
    id: "biochemical_diagnostics",
    field: "Prescription",
  },
  {
    id: "microbiological_diagnostics",
    field: "Prescription",
  },
  {
    id: "cardiological_diagnostics",
    field: "Prescription",
  },
  {
    id: "pulmonary_diagnostics",
    field: "Prescription",
  },
  {
    id: "gastro_intestinal_hepatological_diagnostics",
    field: "Prescription",
  },
  {
    id: "neurological_diagnostics",
    field: "Prescription",
  },
  {
    id: "nephrological_diagnostics",
    field: "Prescription",
  },
  {
    id: "urological_diagnostics",
    field: "Prescription",
  },
  {
    id: "ophthalmological_diagnostics",
    field: "Prescription",
  },
  {
    id: "dermatological_diagnostics",
    field: "Prescription",
  },
  {
    id: "reproductive_diagnostics",
    field: "Prescription",
  },
  {
    id: "ent_diagnostics",
    field: "Prescription",
  },
  {
    id: "endocrinological_diagnostics",
    field: "Prescription",
  },
  {
    id: "orthopedic_diagnostics",
    field: "Prescription",
  },
  {
    id: "pediatric_diagnostics",
    field: "Prescription",
  }
];


export  const fieldNames = {
  homework_assigned: "Advice",
  psychosocial_history: "Social History (SocHx)",
  medical_history: "Clinical History",
  history_of_self_harm_aggression: "History of Self-Harm/Aggression",
  history_of_trauma: "History of Trauma",
  history_of_contagious_medical_conditions: "History of Contagious Medical Conditions",
  medication_history: "Medication History",
  hospitalization_history: "Hospitalization History",
  surgical_history_shx: "Surgical History (SHx)",
  histopathological_pathological_history: "Histopathological/Pathological History",
  allergy_history: "Allergy History",
  nutritional_history: "Nutritional History",
  substance_use_issues: "Substance Dependence History",
  genetics: "Genetics",
  family_history_of_mental_illness_or_substance_abuse_issues: "Family History (FHx)",
  languages_spoken: "Languages Spoken",
  lifestyle_history: "Lifestyle History",
  developmental_history: "Developmental History",
  compliance: "Treatment Adherence",
  treatment_plan_progress: "Treatment Plan Progress",
  assessment_summary: "Assessment Summary",
  worksheet_journal_summary: "Worksheet/Journal Summary",
  stella_sos_summary: `${process.env.REACT_APP_CLIENT_DISPLAY_NAME} SOS Summary`,
  vitals: "Vitals",
  mental_status_exam_results: "Mental Status Exam Results",
  physical_exam_results: "Physical Exam Results",
  histopathological_pathological_diagnostics: "Histopathological/Pathological Diagnostics",
  imaging_and_radiological_diagnostics: "Imaging and Radiological Diagnostics",
  biochemical_diagnostics: "Biochemical Diagnostics",
  microbiological_diagnostics: "Microbiological Diagnostics",
  cardiological_diagnostics: "Cardiological Diagnostics",
  pulmonary_diagnostics: "Pulmonary Diagnostics",
  gastro_intestinal_hepatological_diagnostics: "Gastro-intestinal/Hepatological Diagnostics",
  neurological_diagnostics: "Neurological Diagnostics",
  nephrological_diagnostics: "Nephrological Diagnostics",
  urological_diagnostics: "Urological Diagnostics",
  ophthalmological_diagnostics: "Ophthalmological Diagnostics",
  dermatological_diagnostics: "Dermatological Diagnostics",
  reproductive_diagnostics: "Reproductive Diagnostics",
  ent_diagnostics: "ENT Diagnostics",
  endocrinological_diagnostics: "Endocrinological Diagnostics",
  orthopedic_diagnostics: "Orthopedic Diagnostics",
  pediatric_diagnostics: "Pediatric Diagnostics",
  chief_complaints: "Chief Complaint (CC)",
  history_of_present_illness_hpi: "History of Present Illness (HPI)",
  session_summary: "Session Summary",
  future_treatment_plan: "Future Treatment Plan",
};