import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs, Checkbox } from "@mui/material";
import { AppContext } from "../../../../context";
import {
  CaseHistory,
  fieldNames,
  Followupsummary,
  Prescription,
  SessionNote,
} from "../ClinicalNotesAPI/DropdownData";

// Custom tab panel component
function CustomTabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

// Tabs array
const tabsArray = [
  "Session Note",
  "Prescription",
  "Follow-Up Summary",
  "Case History",
];

// Mapping tabs to corresponding data arrays
const tabDataMap = {
  "Session Note": SessionNote,
  Prescription: Prescription,
  "Follow-Up Summary": Followupsummary,
  "Case History": CaseHistory,
};

// Mapping tabs to section keys
const sectionMap = {
  "Session Note": "progress_note",
  Prescription: "prescription",
  "Follow-Up Summary": "follow_up_summary",
  "Case History": "case_history",
};
const statusMappings = [
  "marital_status",
  "employment_status",
  "marital_functioning",
  "social_functioning",
  "academic_functioning",
];

/**
 * Extracts selected text fields while keeping previous selections.
 */
const getSelectedTextFields = (selectedFields, expertClinicalNotesData) => {
  let extractedText = {};

  // Loop through each section in sectionMap
  Object.entries(sectionMap).forEach(([tab, section]) => {
    if (!expertClinicalNotesData?.data?.[section]) return;

    // Loop through each field in the section
    Object.keys(expertClinicalNotesData.data[section]).forEach((key) => {
      const field = expertClinicalNotesData.data[section][key];

      // Handle medicines separately
      if (selectedFields["medicines"] && key === "medicines") {
        const medications =
          expertClinicalNotesData?.data?.progress_note?.medicines
            ?.prescribed_medicines;

        if (medications?.length > 0) {
          let medicationsText = medications
            .map(
              (medication, index) =>
                `\n${index + 1}. ${medication.drug || ""} ${
                  medication.strength || ""
                } ${medication.dosage_form || ""}, ${
                  medication.quantity || ""
                } to be taken ${medication.route || ""} ${
                  medication.frequency || ""
                }${
                  medication.start_date ? ` from ${medication.start_date}` : ""
                }${medication.end_date ? ` until ${medication.end_date}` : ""}${
                  medication.dispensation
                    ? `, Dispense ${medication.dispensation}`
                    : ""
                }${
                  medication.refills
                    ? ` with ${medication.refills} Repeat(s)`
                    : ""
                }${
                  medication.instructions ? `. ${medication.instructions}` : "."
                }`
            )
            .join(""); // Join all medications

          if (medicationsText.trim()) {
            extractedText.medicines = medicationsText; // Only add if non-empty
          }
        }
        return;
      }

      // Process other selected fields
      if (selectedFields[key]) {
        if (statusMappings.includes(key) && field?.options) {
          const selectedOption = field?.options?.find(
            (opt) => opt.selected === "true"
          );

          if (selectedOption?.option_text) {
            extractedText[key] = selectedOption?.option_text; // Add only if valid text
          }
        } else if (field?.text && field.text?.trim()) {
          extractedText[key] = field.text; // Add only if text is non-empty
        }
      }
    });
  });

  // Handle follow-up date
  if (selectedFields["follow_up_date"]) {
    const followUpDate =
      expertClinicalNotesData?.data?.progress_note?.follow_up_date || "";
    if (followUpDate && followUpDate?.trim()) {
      extractedText.follow_up_date = followUpDate;
    }
  }

  // Handle lab tests
  if (selectedFields["lab_test"]) {
    const labTests =
      expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test;

    if (labTests?.length > 0) {
      let labTestsText = labTests
        .map(
          (test, index) =>
            `\n${index + 1}. ${test.test_name || ""}, Result expected in ${
              test.time_to_result || ""
            }`
        )
        .join("");

      if (labTestsText.trim()) {
        extractedText.lab_test = labTestsText;
      }
    }
  }

  return extractedText;
};

function LetterTemplateDetailsFields({
  selectedFields,
  setSelectedFields,
  clinicalNoteFields,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const { expertClinicalNotesData } = useContext(AppContext);

  // Preserve all selected fields across tabs
  const [allSelectedFields, setAllSelectedFields] = useState(selectedFields);

  useEffect(() => {
    setSelectedFields(allSelectedFields);
  }, [allSelectedFields]);

  // Handle tab change
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Get fields based on selected tab
  const activeTab = tabsArray[selectedTab];
  const activeFields = tabDataMap[activeTab] || [];
  const activeSection = sectionMap[activeTab];

  // Handle checkbox selection & persist selections
  const handleCheckboxChange = (id) => {
    setAllSelectedFields((prev) => {
      const updatedFields = { ...prev };

      if (updatedFields[id]) {
        delete updatedFields[id]; // Remove if already selected
      } else {
        updatedFields[id] = true; // Add if not selected
      }

      return updatedFields;
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAll = () => {
    setAllSelectedFields((prev) => {
      const updatedFields = { ...prev };
      const allSelected = activeFields.every(
        (field) => updatedFields[field.id]
      );

      if (allSelected) {
        // If all are selected, unselect all
        activeFields.forEach((field) => delete updatedFields[field.id]);
      } else {
        // Otherwise, select all
        activeFields.forEach((field) => (updatedFields[field.id] = true));
      }

      return updatedFields;
    });
  };

  // Check if all fields in the current tab are selected
  const isAllSelected =
    activeFields.length > 0 &&
    activeFields.every((field) => allSelectedFields[field.id]);

  // Get selected text fields across all tabs
  useEffect(() => {
    const selectedTextFields = getSelectedTextFields(
      allSelectedFields,
      expertClinicalNotesData
    );
    let text = "";

    Object.entries(selectedTextFields).forEach(([key, value]) => {
      text += `\n <strong>${
        getFieldDisplayName(key)
      }</strong>:\n${value}\n`;
    });

    clinicalNoteFields?.(text);
  }, [allSelectedFields]);

  // Filter tabs based on user data
  const renderedTabs = expertClinicalNotesData?.editTemplate
    ? tabsArray
    : tabsArray.filter((tab) =>
        expertClinicalNotesData?.data?.tab?.includes(tab)
      );

  const tabNames = {
    "Session Note": "Clinical Advice",
    Prescription: "Clinical Examination",
    "Follow-Up Summary": "Follow-Up Note",
    "Case History": "Case History",
  };


 
  
  const getFieldDisplayName = (fieldId) => {
    return (
      fieldNames[fieldId] ||
      fieldId
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase()) // Capitalize words
    );
  };

  return (
    <div
      className="w-full p-2 p-md-3 py-2 py-md-2"
      style={{ background: "#fff" }}
    >
      {/* Header */}
      <div
        style={{
          background: "#fff",
          border: "1px solid #D8D8D8",
          borderRadius: "10px 10px 0px 0px",
        }}
        className="p-4 mt-3 d-flex align-items-center flex-wrap justify-content-between"
      >
        <div>
          <h5>Select Clinical Note Fields</h5>
          <p className="text-muted">Tick a field to add to the letter</p>
        </div>
      </div>

      {/* Tabs */}
      <div
        style={{
          background: "#fff",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D8D8D8",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <div className="SessionDashboard--TabsContainer">
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            className="SessionDashboard--Tabs"
            allowScrollButtonsMobile
          >
            {renderedTabs.map((tab, index) => (
              <Tab key={index} label={tabNames[tab]} />
            ))}
          </Tabs>
        </div>

        {/* Tab Panels */}
        {renderedTabs.map((tab, index) => (
          <CustomTabPanel key={index} value={selectedTab} index={index}>
            <div className="mt-3 p-4 pt-0">
              {/* Select All Checkbox */}
              {activeFields.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    padding: "10px",

                    marginBottom: "10px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Select All</span>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                    color="default"
                    sx={{
                      color: "#D0D5DD",
                      "&.Mui-checked": {
                        color: "#17B26A",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#D0D5DD",
                        borderRadius: "5px",
                        fontSize: 28,
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        backgroundColor: "#17B26A33",
                        color: "#17B26A",
                      },
                    }}
                  />
                </div>
              )}

              {/* Individual Checkboxes */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                  gap: "16px",
                }}
              >
                {activeFields
                  .filter((field) =>
                    expertClinicalNotesData?.data?.template?.some(
                      (item) => item.name === field.id
                    )
                  )
                  .map((field) => (
                    <div
                      style={{
                        border: "1px solid #D8D8D8",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                      key={field.id}
                    >
                      <span style={{ fontWeight: "500" }}>
                        {getFieldDisplayName(field.id)}
                      </span>

                      <Checkbox
                        checked={!!allSelectedFields[field.id]}
                        onChange={() => handleCheckboxChange(field.id)}
                        color="default"
                        sx={{
                          color: "#D0D5DD",
                          "&.Mui-checked": {
                            color: "#17B26A",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#D0D5DD",
                            borderRadius: "5px",
                            fontSize: 28,
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            backgroundColor: "#17B26A33",
                            color: "#17B26A",
                          },
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </CustomTabPanel>
        ))}
      </div>
    </div>
  );
}

export default LetterTemplateDetailsFields;
